import {useState,React} from 'react'
import { TextField,Grid,Button, useMediaQuery, Typography } from '@mui/material'
import Header from './Header'
import Footer from './Footer'
import { Api_url } from './Helper'
import Sim from './Image/Sim.jpeg'
const Contact1 = () => {

    const mobile=useMediaQuery('(max-width:600px)')
    const[Name,setName]=useState('')
  const[Email,setEmail]=useState('')
  const[Phonenumber,setPhoneNumber]=useState('')  
  const[Message,setMessage]=useState('')
     
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Add code to send form data to the server (Node.js backend)
    try {
      const response = await fetch(`${Api_url}/sign/submit-form`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ Name, Email, Phonenumber, Message }),
      });
  
      const data = await response.json();
      console.log('Server response:', data);
  
     
      setName('');
      setEmail('');
      setPhoneNumber('');
      setMessage('');
    } catch (error) {
      console.error('Error sending form data:', error);
    }
  };
  
      
  return (
    <>
    <Header/>
   <Grid container lg={12} xs={11} sx={{margin:mobile?'auto':''}}>
    <Grid container lg={10}  xs={11} sx={{margin:mobile?'auto':'auto'}}>
   
    <Grid item lg={12} xs={10}>
          <Typography sx={{fontSize:'24px',fontWeight:600,}}>
                Contact Us
              </Typography>
              </Grid>
      
        <Grid container lg={6} xs={6} sx={{margin:'auto'}} >
        <Grid item lg={6} xs={10} sx={{ margin: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      
        <img src={Sim} alt="Signup" style={{ width:mobile?'100%': '350px', height: mobile?'100%':'403px' }} />
   </Grid>
       
        </Grid>
      
        <Grid container xs={12} lg={6} sx={{marginBottom:'20px',marginTop:'35px',justifyContent:mobile?'center':''}}>
        <Grid item lg={6} xs={12} sx={{ margin: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      
          <form onSubmit={handleSubmit} style={{width:mobile?'100%':'100%'}}>
           
            
            <Grid item lg={12}>
            <Typography sx={{fontSize:'16px',fontWeight:'600'}}>Name</Typography>
          <TextField
    required
    fullWidth
    variant="outlined"
  
    placeholder="Enter your Name"
    value={Name}
    size="small"
    onChange={(e) => setName(e.target.value)}
    sx={{ mb:mobile?"12px": 2, borderRadius: '6px',backgroundColor:  '#F4F1F1', }} 
  />
  </Grid>
  <Grid item lg={12}>
  <Typography sx={{fontSize:'16px',fontWeight:'600'}}>Email</Typography>
            <TextField
            
    required
    fullWidth
    variant="outlined"
  
    placeholder="Enter your Email"
    value={Email}
    size="small"
    onChange={(e) => setEmail(e.target.value)}
    sx={{ mb:mobile?"12px": 2, borderRadius: '6px',backgroundColor:  '#F4F1F1', }} 
  />
  </Grid>
  <Grid item lg={12}>
  <Typography sx={{fontSize:'16px',fontWeight:'600'}}>Phone number</Typography>
          <TextField
    required
    fullWidth
    variant="outlined"
  
    placeholder="Enter your Phonenumber"
    value={Phonenumber}
    size="small"
    onChange={(e) => setPhoneNumber(e.target.value)}
    sx={{ mb:mobile?"12px": 2, borderRadius: '6px',backgroundColor:  '#F4F1F1', }} 
  />
  </Grid>
  <Grid item lg={12}>
     <Typography sx={{fontSize:'16px',fontWeight:'600'}}>Message</Typography>
          <TextField
    required
    fullWidth
    variant="outlined"
  
    placeholder="Enter your Message"
    value={Message}
    multiline
    rows={4}
    size="small"
    onChange={(e) => setMessage(e.target.value)}
    sx={{ mb:mobile?"12px": 2, borderRadius: '6px',backgroundColor:  '#F4F1F1', }} 
  />
  </Grid>
            <Button variant="contained" type="submit" color="primary" fullWidth sx={{ borderRadius:mobile?"8px": '12px',
            textTransform: 'none',
            width: mobile?"100%":'100%',
            height: mobile?"39px":'50px', fontSize: mobile?"14px":'20px',
            fontFamily: 'Inter',
            fontWeight: '500',marginBottom:mobile?'50px':'0px'}}>
              Submit
            </Button>
          </form>
          </Grid>
        </Grid>
      
   
    </Grid>
   </Grid>
   
   <Footer/>
   </>
  )
}

export default Contact1