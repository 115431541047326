import {React,useEffect,useState} from 'react'
import axios from 'axios'
import { Api_url } from './Helper'
import { Grid,Typography } from '@mui/material'
const Abuton = () => {
const[data,setData]=useState([])
const response=async()=>{
  const res=await axios.get(`${Api_url}/sign/photoallusers`)
  console.log(res.data,'allusers')
  
  setData(res.data)

}
const[data1,setData1]=useState([])
const response1=async()=>{
  const res=await axios.get(`${Api_url}/sign/photoallaccepted`)
  console.log(res.data,'allusers')
  setData1(res.data)

}

useEffect(()=>{
  response()
  response1()
},[])


    const handleAccept =async(itemid)=>  {
        console.log(itemid)
      // Send a request to your backend to handle the accept logic
        fetch(`${Api_url}/sign/api/accept/${itemid}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ action: 'accept' }),
        })
          .then(response => response.json())
          .then(data => console.log(data))
          .catch(error => console.error('Error:', error));
      };
      const handleReject = (itemid) => {
        // Send a request to your backend to handle the reject logic
        fetch(`${Api_url}/sign/api/reject`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ action: 'reject' }),
        })
          .then(response => response.json())
          .then(data => console.log(data))
          .catch(error => console.error('Error:', error));
      };
  return (
    <>
     <Grid container lg={10} sx={{margin:'auto',}}>
      {data.map(item => (
        <Grid item lg={2.8} key={item._id} sx={{backgroundColor:'skyblue',margin:'auto',borderRadius:'26px',border:'1px solid #000'}}>
          <Typography>Status: {item.status}</Typography>
          <Typography>Synopsis: {item.synopsis}</Typography>
          <Typography>Title: {item.title}</Typography>
          <Typography>Location: {item.location}</Typography>
          <Typography>Price: {item.Price}</Typography>
          <Typography>Description: {item.Description}</Typography>
          <Typography>GroupMembers: {item.GroupMembers}</Typography>
          <Typography>Rating: {item.Rating}</Typography>
          <img src={`data:image/png;base64,${item.file}`} alt="Your Image" style={{width:'100px',height:'100px'}} />
          <button onClick={()=>handleAccept(item._id)}>Accept</button>
      <button onClick={handleReject(item._id)}>Reject</button>
          <p>_id: {item._id}</p>
        </Grid>
      ))}
    </Grid>

 <h1>Accept page</h1>
     
 <div>
      {data1.map(item => (
        <div key={item._id}>
          <img src={`data:image/png;base64,${item.file}`} alt="Your Image" />
          <Typography>Status: {item.status}</Typography>
          <Typography>Title: {item.title}</Typography>
          <Typography>Synopsis: {item.synopsis}</Typography>  
          <Typography>Location: {item.location}</Typography>
          <Typography>Price: {item.Price}</Typography>
          <Typography>GroupMembers {item.GroupMembers}</Typography>
          <Typography>Rating: {item.Rating}</Typography>
          
          <button onClick={()=>handleAccept(item._id)}>Accept</button>
      <button onClick={handleReject(item._id)}>Reject</button>
          <p>_id: {item._id}</p>
        </div>
      ))}
    </div>
    </>
  )
}

export default Abuton