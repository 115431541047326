import logo from './logo.svg';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './Home';
import Header from './Header';
import Signup from './Signup';
import Login from './Login';

import Form from './Form';
import Upload from './Upload';
import Abuton from './Abuton';
import Acceptt from './Acceptt';
import Usertours from './Usertours';
import Footer from './Footer'
import Abc from './Contact';
import Contact1 from './Contact1';
function App() {
  return (
    <>
      <Routes>
       <Route path='/'element={<Home />}/>
       <Route path='Header'element={<Header />}/>
       <Route path='/Signup'element={<Signup/>}/>
       <Route path='/Login'element={<Login/>}/>
       <Route path='/Abc'element={<Abc/>}/>
       <Route path='/Form'element={<Form/>}/>
   <Route path='/upload'element={<Upload/>}/>
   <Route path='/Abuton'element={<Abuton/>}/>
   <Route path='/Acceptt'element={<Acceptt/>}/>
   <Route path='/Usertours'element={<Usertours/>}/>
   <Route path='/Footer'element={<Footer/>}/>
   <Route path='/contact'element={<Contact1/>}/>
      </Routes>
    </>
  );
}

export default App;
