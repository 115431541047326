import React, { useState } from 'react';
import Image from 'mui-image';
import Lot from './Image/Lot.jpeg'
import {
  Grid,
  Button,
  Avatar,
  Menu,
  MenuItem,
  Drawer,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useNavigate } from 'react-router-dom';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { useMediaQuery } from '@mui/material';

const Header = () => {
  const mobile = useMediaQuery('(max-width:600px)');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  const handleUpload = () => {
    navigate('/Usertours');
  };

  const handleUpload2 = () => {
    navigate('/Acceptt');
  };

  const handleUpload3 = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  const handleClose = () => {
    setDrawerOpen(!drawerOpen);
    console.log('Close button');
  };
  const handleClose1 = () => {
    setDrawerOpen(!drawerOpen);
    console.log('Close button');
  };

  const renderDesktopButtons = () => {
    return (
    
      <Grid container lg={4} xs={4} sx={{ justifyContent: 'flex-end', alignItems: 'center' }}>
      
        <Link to="/Acceptt">
          <Button sx={{ textTransform: 'none',  fontSize: '19px' }}>Get-User</Button>
        </Link>
        <Link to="/Usertours">
          <Button sx={{ textTransform: 'none',  fontSize: '19px' }}>Usertours</Button>
        </Link>
        <Link to="/contact">
          <Button sx={{ textTransform: 'none',  fontSize: '19px' }}>Contact</Button>
        </Link>
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Avatar alt="User Avatar" src={PersonAddIcon} {...bindTrigger(popupState)} />
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={handleUpload}>My Purchase</MenuItem>
                <MenuItem onClick={handleUpload2}>Account Settings</MenuItem>
                <MenuItem onClick={handleUpload3}>Logout</MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      </Grid>
    );
  };
  const renderDesktopButtons2 = () => {
    return (
    
      <Grid container lg={4} xs={10} sx={{ justifyContent: 'flex-end', alignItems: 'center',margin:'auto' }}>
     
     <Grid item lg={12} xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <IconButton onClick={handleClose1}>
          <CloseIcon />
        </IconButton>
      </Grid>
       <Grid item xs={12} sx={{justifyContent: 'flex-end', alignItems: 'center',display:'flex',flexDirection: 'column'}}>
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Avatar alt="User Avatar" src={PersonAddIcon} {...bindTrigger(popupState)} style={{width:'120px',height:'120px',marginTop:'20px',marginBottom:'20px'}} />
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={handleUpload}>My Purchase</MenuItem>
                <MenuItem onClick={handleUpload2}>Account Settings</MenuItem>
                <MenuItem onClick={handleUpload3}>Logout</MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
        </Grid>
        
      <Grid item xs={12}  sx={{justifyContent: 'center', alignItems: 'center',display:'flex'}}>
        <Link to="/Acceptt">
          <Button sx={{ textTransform: 'none',  fontSize: '19px' }}>Get-User</Button>
        </Link>
        </Grid>
        <Grid item xs={12}  sx={{justifyContent: 'center', alignItems: 'center',display:'flex'}}>
        <Link to="/Usertours">
          <Button sx={{ textTransform: 'none',  fontSize: '19px' }}>Usertours</Button>
        </Link>
        </Grid>
        <Grid item xs={12}  sx={{justifyContent: 'center', alignItems: 'center',display:'flex'}}>
        <Link to="/contact">
          <Button sx={{ textTransform: 'none',  fontSize: '19px' }}>Contact</Button>
        </Link>
        </Grid>
       
      </Grid>
    );
  };

  const renderMobileButtons = () => {
    return (
      <Grid container lg={3} xs={2} sx={{ justifyContent: 'flex-end', alignItems: 'center' }}>
        <IconButton color="inherit" onClick={toggleDrawer}>
          <MenuIcon />
        </IconButton>
      </Grid>
    );
  };

  return (
    <>
      <Grid container lg={12} xs={10} sx={{margin:mobile?'auto':''}}>
        <Grid container lg={10.5} xs={12} sx={{ margin: 'auto' }}>
          <Grid container lg={8} xs={10}>
            <Grid item lg={2}>
              <Image
                src={Lot}
                style={{ width: '70px', height: '70px' }}
              />
            </Grid>
          </Grid>
          {mobile ? renderMobileButtons() : token ? renderDesktopButtons() :<>
              <Grid container lg={3.7} sx={{ justifyContent: mobile ? 'flex-end' : 'flex-end', alignItems: 'center' }}>
                <Link to="/Login">
                  <Button sx={{ textTransform: 'none',backgroundColor:'Skyblue' ,color:'black', fontSize: '14px' ,marginRight: '10px','&:hover': {
          backgroundColor: 'DodgerBlue',color:'#fff' 
        },
        '&:active': {
          backgroundColor: 'DarkBlue', 
        },}}>Login</Button>
                </Link>
                <Link to="/Signup">
                  <Button sx={{ textTransform: 'none',backgroundColor:'Skyblue' ,color:'black', fontSize: '14px' ,marginRight: '10px','&:hover': {
          backgroundColor: 'DodgerBlue',color:'#fff' 
        },
        '&:active': {
          backgroundColor: 'DarkBlue', 
        },}}>Signup</Button>
                </Link>
                <Link to="/contact">
                  <Button sx={{ textTransform: 'none',backgroundColor:'Skyblue' ,color:'black', fontSize: '14px' ,marginRight: '10px','&:hover': {
          backgroundColor: 'DodgerBlue',color:'#fff' 
        },
        '&:active': {
          backgroundColor: 'DarkBlue', 
        },}}>Contact</Button>
                </Link>
              </Grid>
            </> }
        </Grid>
      </Grid>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
        <div>
          {token ? (
            <>
              {renderDesktopButtons2()}
            </>
          ) : (
            <>
              <Grid container lg={3} sx={{ justifyContent:mobile?'flex-end':'flex-end', alignItems: 'center' }}>
              <Grid container lg={3} xs={3}>
              <Grid item lg={12} xs={12}>
              <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Grid>
      </Grid>
               <Grid item lg={12} xs={12}>
                <Link to="/Login">
                  <Button sx={{ textTransform: 'none',  fontSize: '19px' }}>Login</Button>
                </Link>
                </Grid>
                <Grid item lg={12} xs={12}>
                <Link to="/Signup">
                  <Button sx={{ textTransform: 'none',  fontSize: '19px' }}>Signup</Button>
                </Link>
                </Grid>
                <Grid item lg={12} xs={12}>
                <Link to="/contact">
                  <Button sx={{ textTransform: 'none',  fontSize: '19px' }}>Contact</Button>
                </Link>
              </Grid>
            

      
              </Grid>


              
            </>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default Header;
