import {React,useState} from 'react'
import {jwtDecode} from 'jwt-decode'
import axios from 'axios'
import { Api_url } from './Helper'
const Abc = () => {
 const[user,setUser]=useState({})
 
const handleclick=async()=>{
  const user=localStorage.getItem('token')
  const userId=jwtDecode(user)
  console.log(userId)
  const ab=userId._id
  console.log(ab)
  const response=await axios.get(`${Api_url}/sign/userid/${ab}`)
  console.log(response.data)
  setUser(response.data)
    }
  return (
    <div>Contact
      <p>{user.Name}</p>
      <p>{user.Email}</p>
    <button onClick={handleclick}>click</button>
    </div>
  )
}

export default Abc