import React from 'react';
import Image from 'mui-image';
import { Grid, Button, IconButton, useMediaQuery,Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Facebook,Twitter } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import Lot from './Image/Lot.jpeg'
const Footer = () => {
  const mobile=useMediaQuery('(max-width:600px)')
  return (
    <>
      <Grid container lg={12} xs={!2} sx={{margin:'auto'}}>
        <Grid container lg={12}  xs={10}>
         
        <Grid container lg={2} xs={12}>

<Grid item lg={12}  sx={{    display: 'flex',justifyContent:'center',alignItems:'center'}}  >
    <Grid item lg={2}  sx={{   display: 'flex',justifyContent:'center',
alignItems:'center'}}  >
<Image
Duration={0}
src={Lot}
style={{

width: '138px',
height: '60px',
transitionDuration: '0',
animation: '0',
}}
/>
</Grid>
</Grid>
</Grid>

         
          <Grid container lg={3} xs={10} sx={{margin:'auto',marginTop:mobile?'26px':''}}>
<Grid item lg={2} xs={12}  sx={{    display: 'flex',
   justifyContent:mobile?'initial':'center',
   alignItems:'center'}} >   <Link to="/Terms" style={{ textDecoration: 'none' ,color:'#1e1e1e'}}>
    <Typography>
    Terms
    </Typography>
    </Link>
</Grid>
<Grid item lg={4} xs={12}  sx={{    display: 'flex',
   justifyContent:mobile?'initial':'center',
   alignItems:'center'}} >
      <Link to="/Privacy" style={{ textDecoration: 'none' ,color:'#1e1e1e'}}>
    <Typography sx={{marginTop:mobile?'12px':''}}>
    Privacy
    </Typography>
    </Link>
</Grid>
<Grid item lg={3} xs={12} sx={{    display: 'flex',
 justifyContent:mobile?'initial':'center',
   alignItems:'center'}} >
     <Link to="/" style={{ textDecoration: 'none' ,color:'#1e1e1e'}}>
    <Typography sx={{marginTop:mobile?'12px':''}} >
    About us
    </Typography>
    </Link>
</Grid>
<Grid item lg={2.5} xs={12} sx={{ display: 'flex', justifyContent: mobile ? 'initial' : 'center', alignItems: 'center' }}>
    <Link to="/Help" style={{ textDecoration: 'none' ,color:'#1e1e1e'}}>
        <Typography sx={{ marginTop: mobile ? '12px' : '' }}>
            Help
        </Typography>
    </Link>
</Grid>
</Grid> 

         
          <Grid container lg={2} xs={12}>
    <Grid item lg={12} xs={12} >

<Grid container spacing={2} xs={12} sx={{justifyContent:mobile?'flex-end':'',marginBottom:mobile?'26px':'',marginTop:mobile?'26px':''}}>
      <Grid item>
      <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faFacebook} size="2x" />
      </a>
      </Grid>
      <Grid item>
      <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faInstagram} size="2x" />
      </a>
      </Grid>
   
    </Grid>
    </Grid>
</Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Footer;
