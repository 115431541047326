import {React,useState,useEffect} from 'react'
import axios from 'axios'
import { jwtDecode } from 'jwt-decode';
import { Button, Grid,Typography,Paper } from '@mui/material'
import {Rating} from '@mui/material'
import Header from './Header'
import Footer from './Footer'
import {Skeleton} from '@mui/material'
import {useMediaQuery} from '@mui/material'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { Api_url } from './Helper';
const Acceptt = () => {
  const [openItemId, setOpenItemId] = useState(null);
  const[open,setopen]=useState(false)
  const mobile = useMediaQuery('(max-width:600px)');
  const[loading,setloading]=useState(false)
    const[data1,setData1]=useState([])
    const handleCloseDialog = () => {
      // Close the dialog
      setOpenItemId(null);
    };
    const handleAccept =async(itemid)=> {
        console.log(itemid)
        const token = localStorage.getItem('token');
        const user = jwtDecode(token);
        const userId = user._id;
        console.log(userId)
        setOpenItemId(itemid)
        console.log(openItemId)
        setopen(true)
      // Send a request to your backend to handle the accept logic
      try {
        // Make a POST request to your Node.js server for handling the purchase
        const response = await fetch(`${Api_url}/sign/api/purchased/${itemid}/${userId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          }
        });
    
     console.log(response.data,'yes',response)
        if (response.ok) {
         
          console.log('Purchase successful');
        } else {
          console.error('Purchase failed');
        }
      } catch (error) {
        console.error('Error during purchase:', error);
      }
      };
      const response1=async()=>{
        const res=await axios.get(`${Api_url}/sign/photoallaccepted`)
        console.log(res.data,'allusers')
        setData1(res.data)
        setloading(false)
      
      }
      useEffect(()=>{
      setloading(true)
        response1()
      },[])
      const truncateSynopsis = (text, maxLength) => {
        if (text.length > maxLength) {
          return text.substring(0, maxLength) + '...';
        }
        return text;
      };
      const openDialog = openItemId !== null;
  return (
    <>
<Header/>
{loading ? (<Grid container lg={10.5} xs={12} spacing={mobile?0:3} justifyContent="center" sx={{margin:'auto'}}>
<Grid item  xs={11} sm={6} md={4} lg={4} sx={{padding:'0px'}} >
            <Skeleton
              variant="rectangular"
              width="100%"
              height={200}
              style={{ borderRadius: '16px', marginBottom: '20px' }}
              animation="wave"
              sx={{ backgroundColor: 'aliceblue' }}
            />
          </Grid>
          <Grid item  xs={11} sm={6} md={4} lg={4} sx={{padding:'0px'}} >
            <Skeleton
              variant="rectangular"
              width="100%"
              height={200}
              style={{ borderRadius: '16px', marginBottom: '20px' }}
              animation="wave"
              sx={{ backgroundColor: 'aliceblue' }}
            />
          </Grid>
          <Grid item  xs={11} sm={6} md={4} lg={4} sx={{padding:'0px'}} >
            <Skeleton
              variant="rectangular"
              width="100%"
              height={200}
              style={{ borderRadius: '16px', marginBottom: '20px' }}
              animation="wave"
              sx={{ backgroundColor: 'aliceblue' }}
            />
          </Grid>
          <Grid item  xs={11} sm={6} md={4} lg={4} sx={{padding:'0px'}} >
            <Skeleton
              variant="rectangular"
              width="100%"
              height={200}
              style={{ borderRadius: '16px', marginBottom: '20px' }}
              animation="wave"
              sx={{ backgroundColor: 'aliceblue' }}
            />
          </Grid>
          <Grid item  xs={11} sm={6} md={4} lg={4} sx={{padding:'0px'}} >
            <Skeleton
              variant="rectangular"
              width="100%"
              height={200}
              style={{ borderRadius: '16px', marginBottom: '20px' }}
              animation="wave"
              sx={{ backgroundColor: 'aliceblue' }}
            />
          </Grid>
          <Grid item  xs={11} sm={6} md={4} lg={4} sx={{padding:'0px'}} >
            <Skeleton
              variant="rectangular"
              width="100%"
              height={200}
              style={{ borderRadius: '16px', marginBottom: '20px' }}
              animation="wave"
              sx={{ backgroundColor: 'aliceblue' }}
            />
          </Grid>
          </Grid>) :
(<Grid container lg={10.5} xs={12} spacing={mobile?0:3} justifyContent="center" sx={{margin:'auto'}}>
        {data1.map((item) => (
          <Grid item key={item._id} xs={11}  lg={4} sx={{padding:'0px'}} >
          
      <Paper elevation={3} sx={{ padding: '20px', borderRadius: '16px',backgroundColor:'aliceblue',marginBottom:'20px' }}>
              <img
                src={`data:image/png;base64,${item.file}`}
                alt="Your Image"
                style={{
                  width: '100%',
                  height: '200px',
                  borderRadius: '16px',
                  objectFit: 'cover',
                }}
              />
              <Typography variant="h6" mt={2}>
                {item.title}
              </Typography>
              <Rating value={item.Rating} mt={1} />
              <Typography>{`Description: ${truncateSynopsis(item.synopsis, 130)}`}</Typography>
              <Typography>{`Members: ${item.GroupMembers}`}</Typography>
              <Typography>{`Location: ${item.location}`}</Typography>
              <Button
                variant="contained"
                onClick={() => handleAccept(item._id)} 
                mt={2}
                fullWidth
                sx={{
                  borderRadius: '8px', // Set the desired border radius
                  marginTop: '10px',textTransform:'none' // Set the desired marginTop
                }}
              >
                Buy now -- {item.Price}
              </Button>
              <Dialog key={item._id}
            open={openItemId===item._id}
            onClose={handleCloseDialog}
          
          >
            <DialogTitle>Confirmation</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to buy this item for {item.Price}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleCloseDialog} color="primary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
            </Paper>
          </Grid>
        ))}
      </Grid>)}

   <Footer/>
    </>
  )
}

export default Acceptt