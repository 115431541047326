import {React,useState} from 'react'
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import {Grid,Paper,Button} from '@mui/material';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import { Api_url } from './Helper';
const Upload = () => {
    const[Title,setTitle]=useState('')
    const [Synopsis, setSynopsis] = useState('');
    const [Location, setLocation] = useState('');
    const [Price, setPrice] = useState('');
    const [GroupMembers, setGroupMembers] = useState('');
    const [Description, setDescription] = useState('');
    const [Rating, setRating] = useState('');


    const[user,setUser]=useState({})
    const [file, setFile] = useState(null);
    const[filed,setfiled]= useState('')
    const[photo,setphoto]=useState('')
    const handleFileChange = (event) => {
      const file=event.target.files[0]
        setfiled(file);
      };
      const handleclick=async()=>{
        // const photo=localStorage.getItem('token')
        // const userphoto=jwtDecode(photo)
        // console.log(userphoto)
        const abc='ghfgh'
      
        try {
          const response = await axios.get(`${Api_url}/sign/filephotoc/${abc}`, {
          responseType: 'arraybuffer',
          headers: {
            Accept: 'image/png, image/jpeg',
          },
          });
          console.log(response.data)
          const blob = new Blob([response.data], { type: response.headers['content-type'] });
          const photo= URL.createObjectURL(blob);
          setphoto(photo)
        } catch (error) {
          console.error(error);
          return null;
        }
    }
      const handleupload1=()=>{
        if (filed){
          const formdata=new FormData()
          const photo=localStorage.getItem('token')
          const userphoto=jwtDecode(photo)
          console.log(userphoto)
          const abc=userphoto._id
          console.log(abc)
          formdata.append('photo',filed)
          formdata.append('userId',abc)
          formdata.append('title',Title)
          formdata.append('synopsis',Synopsis)
          formdata.append('Location',Location)
          formdata.append('Price',Price)
          formdata.append('Description',Description)
          formdata.append('GroupMembers',GroupMembers)
          formdata.append('Rating',Rating)
         
          axios.post(`${Api_url}/sign/users/synopsis`,formdata)
        }}
  return (

    <>
    <Header/>
    <Grid container lg={12} sx={{backgroundColor:'aliceblue',display:'flex',justifyContent:'center'}}>
        <Grid container lg={10} sx={{margin:'auto'}}>
      

           
            <Grid container lg={10} sx={{justifyContent:'center'}}>
            <Grid item lg={10} xs={11.2}>
			<Typography sx={{ textAlign: 'left', fontSize: '16px', fontWeight: '700', fontFamily: 'Inter', marginTop: '8px' }}>
			  Title
			</Typography>
			<TextField
			  required
			  fullWidth
			  variant="outlined"
			  placeholder="Write your title"
			  value={Title}
			  size="small"
			  onChange={(e) => setTitle(e.target.value)}
			  sx={{ mb: 2, borderRadius: '6px',  marginTop:'8px', }}
			  
			/>
		  </Grid>
      <Grid item lg={10} xs={11.2}>
	<Typography sx={{ textAlign: 'left', fontSize: '16px', fontWeight: '700', fontFamily: 'Inter', marginTop: '8px' }}>
	  Synopsis
	</Typography>
	<TextField
	  required
	  fullWidth
	  variant="outlined"
	  placeholder="Write your synopsis"
	  value={Synopsis}
	  size="small"
	  onChange={(e) => setSynopsis(e.target.value)}
	  sx={{ mb: 2, borderRadius: '6px', marginTop:'8px', }}
	/>
  </Grid>
  <Grid item lg={10} xs={11.2}>
  <Typography sx={{ textAlign: 'left', fontSize: '16px', fontWeight: '700', fontFamily: 'Inter', marginTop: '8px' }}>
	  Location
	</Typography>
  <TextField
  required
  fullWidth
  variant="outlined"
  placeholder="Location"
  value={Location}
  size="small"
  onChange={(e) => setLocation(e.target.value)}
  sx={{ mb: 2, borderRadius: '6px', marginTop: '8px' }}
/>

</Grid>

<Grid item lg={10} xs={11.2}>
<Typography sx={{ textAlign: 'left', fontSize: '16px', fontWeight: '700', fontFamily: 'Inter', marginTop: '8px' }}>
	  Price
	</Typography>
<TextField
  required
  fullWidth
  variant="outlined"
  placeholder="Per Person Price"
  value={Price}
  size="small"
  onChange={(e) => setPrice(e.target.value)}
  sx={{ mb: 2, borderRadius: '6px', marginTop: '8px' }}
/>
</Grid>
<Grid item lg={10} xs={11.2}>
<Typography sx={{ textAlign: 'left', fontSize: '16px', fontWeight: '700', fontFamily: 'Inter', marginTop: '8px' }}>
	  Description
	</Typography>
<TextField
  required
  fullWidth
  variant="outlined"
  placeholder="Description"
  value={Description}
  size="small"
  onChange={(e) => setDescription(e.target.value)}
  sx={{ mb: 2, borderRadius: '6px', marginTop: '8px' }}
/>
</Grid>
<Grid item lg={10} xs={11.2}>
<Typography sx={{ textAlign: 'left', fontSize: '16px', fontWeight: '700', fontFamily: 'Inter', marginTop: '8px' }}>
	  Group Members
	</Typography>
<TextField
  required
  fullWidth
  variant="outlined"
  placeholder="Group Members"
  value={GroupMembers}
  size="small"
  onChange={(e) => setGroupMembers(e.target.value)}
  sx={{ mb: 2, borderRadius: '6px', marginTop: '8px' }}
/>
</Grid>
<Grid item lg={10} xs={11.2}>
<Typography sx={{ textAlign: 'left', fontSize: '16px', fontWeight: '700', fontFamily: 'Inter', marginTop: '8px' }}>
	  Rating
	</Typography>
<TextField
  required
  fullWidth
  variant="outlined"
  placeholder="Per Person Price"
  value={Rating}
  size="small"
  onChange={(e) => setRating(e.target.value)}
  sx={{ mb: 2, borderRadius: '6px', marginTop: '8px' }}
/>
</Grid>




<Grid item lg={10}>
    <Typography>{user.Name}</Typography>
    <img src={photo} sx={{ width: '100px', height: '100px' }} />
    <Typography>Upload Photo:</Typography>
    <input type="file" onChange={handleFileChange} />
  </Grid>
  <Grid item lg={10} sx={{marginTop:'20px',marginBottom:'20px'}}>
    <Button onClick={handleupload1}   sx={{  border: '1px solid #000',
          backgroundColor: 'transparent',
          padding: '8px 16px',
          cursor: 'pointer',
          borderRadius: '10px',textTransform:'none',marginRight:'10px'}}>Submit</Button>

    
  </Grid>

</Grid> 


 </Grid>
        </Grid>
   
        
        <Footer/>
        </>
  )
}

export default Upload