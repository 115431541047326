import {React,useState} from 'react'
import { Typography, Grid, Paper, Button,Box } from '@mui/material';
import { TextField } from '@mui/material';
import {useMediaQuery} from '@mui/material';
import Header from './Header';
import Image  from 'mui-image'
import {Link} from 'react-router-dom'
import { styled } from '@mui/system';
import Tomm from './Image/Tomm.webp'
import Footer from './Footer';
import Sec from './Image/Sec.jpeg'
import Sec1 from './Image/Sec1.jpeg'
import Sec3 from './Image/Sec3.png'
const CustomButton = styled(Button)`
  &:hover {
    background-color: #5A9BC4; /* or specify the desired background color */
  }
`;
const Home = () => {
    const mobile = useMediaQuery('(max-width:600px)');
    const [email, setEmail] = useState('');
    const handleSubmit = async () => {
      try {
        // Send the email to your account through an API or email service here.
        // You'll need a backend for this part.
      } catch (error) {
        console.error('Error sending email:', error);
      }
    };
  return (
    <>
    <Header/>
   <Grid container lg={12} xs={12}>
    <Grid container lg={10} xs={10.8} sx={{margin:'auto',marginTop:'62px',}}>
        <Grid container lg={7} xs={10.8} sx={{margin:mobile?'auto':''}}>
<Grid item lg={12} xs={12}>
<Typography sx={{ fontWeight:mobile?'800': '700', fontSize:mobile?'32px': '61px', color:'#1E1E1E',  lineHeight:mobile?'normal': '77px', fontFamily:mobile?'Inter':'Inter',fontStyle:'normal'}}>Your<br/> Journey Starts Here</Typography>
</Grid>
<Grid item  lg={12}  xs={12}>
						
								
                        <Grid item  lg={9.5} xs={12} >
            <Typography  sx={{ fontWeight:mobile?'400': '400', fontSize: mobile?'14px':'20px',fontFamily:mobile?'Inter':'Inter',fontStyle:'normal',lineHeight: '130%' ,marginTop:mobile?'20px':'20px'}}>
            Unlocking the world's wonders, <span style={{fontWeight:'600'}}>one journey at a time</span> Tailored travel experiences to make your dreams come true.
</Typography>
            </Grid>
<Grid item lg={11} xs={12}sx={{marginTop:'16px'}} >
                    <Typography  sx={{ fontWeight: '400', fontSize:mobile?'14px': '20px',  fontFamily:'Inter',fontStyle:'normal',lineHeight: '130%'  }}>Your gateway to global adventures.<span style={{fontWeight:'600'}}>Personalized travel </span> experiences that create lifelong memories.</Typography>
                </Grid>
                <Grid item lg={10.5} xs={12} sx={{marginTop:'16px'}} >
                    <Typography  sx={{ fontWeight: '400', fontSize:mobile?'14px': '20px',  fontFamily:'Inter',fontStyle:'normal',lineHeight: '130%'  }}>Crafting <span style={{fontWeight:'600'}}>extraordinary adventures</span> where you're the author of your travel story. Let us take you there. </Typography>
                </Grid>
                

      </Grid>	
        </Grid>
        <Grid container lg={5} xs={10.5} sx={{margin:'auto'}}>
           <Grid item lg={12} sx={{marginTop:mobile?'-100px':'0px'}}>
<Image src={Tomm}  style={{
    width:mobile?"310px":'100%',
    height:mobile?'290px': '100%',
    marginTop: mobile ? '156px' : '0px',
    transitionDuration: '0',
    animation: '0',
    zIndex: 1,
   
  
  }}></Image>
           </Grid>
        </Grid>
        
    
    <Grid container lg={12} xs={11} sx={{margin:mobile?'auto':''}}>
  <Grid item lg={12}  xs={12} >
    <Typography sx={{textAlign:'center',marginTop:'80px',fontSize:mobile?'14px':'24px'}}>ABOUT US</Typography>
  </Grid>
  <Grid container lg={12} >
  <Grid item lg={4.2} xs={10.5} sx={{margin:'auto'}} >
    <Typography sx={{textAlign:'center',color:'#171717',marginTop:'20px',fontWeight:'600',fontSize:mobile?'14px':'18px'}}>Simplifies travel, plans, and bookings for hassle-free trips</Typography>
  </Grid>
  </Grid>
  <Grid container lg={12}  xs={10.5}>
  <Grid item lg={8} xs={10.5} sx={{margin:mobile?'auto':'auto'}}>
<Image
        Duration={0}
        src={Sec}
        style={{
          justifyContent: 'space-around',
         width:mobile?'289px':'75%',
         width:mobile?'300px':'75%',
          transitionDuration: '0',
          animation: '0',
          
        }}
      />
</Grid>
</Grid>
   </Grid>

   <Grid container lg={12} xs={10.5} sx={{margin:mobile?'auto':'',}} >
  <Grid container lg={12} xs={11.5} sx={{marginTop:'75px'}}>
  <Grid container lg={6}  xs={12} sx={{marginTop:mobile?'-60px':"0px"}}>
  <Grid item lg={12} xs={12}>
    <Typography sx={{textAlign:"initial", marginTop: '32px', fontFamily: mobile?'Inter': 'Inter', fontSize: mobile?'16px':'26px', fontWeight: mobile?'600': "600",  }}></Typography>
  </Grid>
  <Grid item lg={12} xs={12}>
    <Typography sx={{textAlign:mobile?'left':'left', fontFamily: mobile?'Inter': 'Inter', fontSize: mobile?'26px': '48px', fontWeight:mobile?'700':"700", color: '#1E1E1E' }}>Wanderland</Typography>
  </Grid>
  <Grid item lg={12} xs={12}>
    <Typography sx={{textAlign:'left', fontFamily:mobile?'Inter': 'Inter', fontSize:mobile?'14px':'20px', fontWeight: mobile?'400': "400", color: '#1E1E1E',lineHeight:'146%' }}><span style={{fontWeight:'600'}}>A travel agency is a company or organization</span> that specializes in planning and arranging travel and tourism services for individuals, families, and groups come together in a story of the battle between good and evil.<br/>
</Typography>
  </Grid>
  <Grid item lg={12} xs={12}>
    <Typography sx={{textAlign:'left', fontFamily: mobile?'Inter': 'Inter', fontSize: mobile?'14px': '16px', fontWeight: mobile?'400': "400" ,marginTop:'12px'}}></Typography>
  </Grid>
  <Grid item lg={1.5} xs={10} sx={{ marginTop: '8px', display: mobile?'flex':'', justifyContent: mobile ? 'left' : '',  }} >
  <img
    duration={0}
    src={Sec1}
    style={{
   
      width: '63px',
      height: '77px',
      transitionDuration: '0',
      animation: '0',
    }}

  />
  </Grid>
</Grid>
<Grid container lg={6} xs={11} sx={{marginTop:mobile?'31px':'0px'}}>
<Grid item lg={12}  xs={12} sx={{backgroundPosition:'center',backgroundRepeat:'no-repeat',}}>

								  <Image
									  duration={0}
									  src={Sec3}
									  style={{ width:mobile?'290px': '350px', height:mobile?'270px': '350px', transitionDuration: '0', animation: '0', zIndex: 1 ,borderRadius:'70%'}}
        />

</Grid>    
 

    </Grid>
  </Grid>
</Grid>

<Box sx={{backgroundColor:'whitesmoke',width:mobile?"320px":'1140px',height:mobile?"300px": '272px',borderRadius: '12px',margin:'100px',marginLeft:'auto',marginRight:'auto',marginBottom:mobile?"20px":'20px'}}>
					<Grid container lg={10} xs={12} >
						<Grid item  lg={9} xs={11} sx={{textAlign:mobile?"center":'center',display:'flex',margin:mobile?'auto':'auto',marginTop:mobile?'36px':'26px'}}>
							<Typography variant='h3' sx={{margin:'auto',
						fontStyle: 'normal',
						fontWeight: 600,
						fontSize: mobile?'14px':'24px',
						lineHeight: mobile?'20px':'40px',
						
						
						textAlign: mobile?"center":'center',marginTop:'73px'
								
						
								
								
								
					}}> Sign up here to start your travel adventures with us</Typography>
						
					</Grid>
          <Grid container lg={12} xs={12} sx={{display:'flex',justifyContent:"center",marginTop:'30px'}}  >
            <Grid containe lg={7} xs={12} sx={{marginLeft:mobile?'-136px':'0px'}} >
          <Grid item lg={10} xs={10} sx={{marginLeft:'103px'}} >
          <TextField
        label="Email"
        variant="outlined"
        fullWidth
        value={email}
        size='small'
        onChange={(e) => setEmail(e.target.value)}
      />
      </Grid>
      </Grid>
      <Grid container lg={3} xs={12}>
  <Grid item lg={12} xs={12}sx={{margin:'auto',marginLeft:mobile?'36px':'43px'}}  >
    <Button style={{width:mobile?'210px':'100px',height:'35px',backgroundColor: 'steelblue',marginTop:mobile?'12px':'0px',textTransform:'none'}}
      variant="contained"
      color="primary"
      onClick={handleSubmit}
    >
      Submit
    </Button>
 </Grid>
 </Grid>
    </Grid>
    </Grid>
    </Box>
   </Grid>
</Grid>
<Footer/>
    </>
  )
}

export default Home