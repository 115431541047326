import { jwtDecode } from 'jwt-decode'
import {React,useState} from 'react'
import { Api_url } from './Helper'
import axios from 'axios'
const Form = () => {
    const[user,setUser]=useState({})
    const[photo,setphoto]=useState('')
    const handleclick=async()=>{
        const photo=localStorage.getItem('token')
        const userphoto=jwtDecode(photo)
        console.log(userphoto)
        const abc=userphoto._id
        console.log(abc)
        try {
          const response = await axios.get(`${Api_url}/sign/userphoto/${abc}`, {
          responseType: 'arraybuffer',
          headers: {
            Accept: 'image/png, image/jpeg',
          },
          });
          const blob = new Blob([response.data], { type: response.headers['content-type'] });
          const photo= URL.createObjectURL(blob);
          setphoto(photo)
        } catch (error) {
          console.error(error);
          return null;
        }
    }
    const[filed,setfiled]=useState(null)
const handlefile=(event)=>{
  const file=event.target.files[0]
  setfiled(file)
}

const handleupload=()=>{
  if (filed){
    const formdata=new FormData()
    const photo=localStorage.getItem('token')
    const userphoto=jwtDecode(photo)
    console.log(userphoto)
    const abc=userphoto._id
    console.log(abc)
    formdata.append('photo',filed)
    formdata.append('userId',abc)
    axios.post(`${Api_url}/sign/users/photo`,formdata)
  }
}
    
  return (
    <>
    Form
    <p>{user.Name}</p>
    <img src={photo}/>
    <input type='file' onChange={handlefile}/>
    <button onClick={handleclick}>Clicked</button>
    <button onClick={handleupload}>upload</button>
    
    </>
  )
}

export default Form