import React, { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import Header from './Header'
import Footer from './Footer';
import { Typography, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Api_url } from './Helper';
const Usertours = () => {
  const [tours, setTours] = useState([]);
  const handlePurchase = async () => {
    const token = localStorage.getItem('token');
    const user = jwtDecode(token);
    const userId = user._id;
  
    try {
      const response = await axios.get(`${Api_url}/sign/purchasedTours/${userId}`);
      console.log(response.data, 'purchased');
      setTours(response.data);
    } catch (error) {
      console.error('Error during purchase:', error);
    }
  };
  
  useEffect(() => {
    handlePurchase();
  }, []);

  return (
    <>
    <Header/>
   <Grid container lg={12} xs={11} sx={{marginTop:'34px'}}>
  <Grid container lg={10} xs={11} sx={{ margin: 'auto' }}>
    <Grid container item lg={8}  xs={8}  >
      <Typography variant="h4" style={{  borderBottom: '1px solid #000', fontSize: '20px', fontWeight: '600' }}>
        User Tours
      </Typography>
    </Grid>
    <Grid container item lg={4}  xs={4} justifyContent="flex-end">
      <button
        onClick={handlePurchase}
        style={{
          border: '1px solid #000',
          backgroundColor: 'transparent',
          padding: '8px 16px',
          cursor: 'pointer',
          borderRadius: '10px'
        }}
      >
        Purchase
      </button>
    </Grid>
    <Typography sx={{fontSize:'15px', marginTop:'20px'}}>
    You've booked your  trip! Enjoy your journey and make every moment unforgettable. Happy travels!!
    </Typography>
    <TableContainer component={Paper} style={{ backgroundColor: 'skyblue', marginTop: '20px' ,borderRadius:'15px',border:'1px solid #1e1e1e',marginBottom:'90px'}}>
      <Table>
        <TableHead>
          <TableRow style={{ backgroundColor: '#f2f2f2' }}>
            <TableCell style={{ borderBottom: '1px solid #000', borderRight: '1px solid #000' }}>Title</TableCell>
           <TableCell style={{ borderBottom: '1px solid #000', borderRight: '1px solid #000' }}>Location</TableCell>
            <TableCell style={{ borderBottom: '1px solid #000', borderRight: '1px solid #000' }}>Price</TableCell>
            <TableCell style={{ borderBottom: '1px solid #000', borderRight: '1px solid #000' }}>Purchase</TableCell>
            <TableCell style={{ borderBottom: '1px solid #000', borderRight: '1px solid #000' }}>Rating</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tours.map((tour) => (
            <TableRow key={tour._id} >
              <TableCell style={{ borderBottom: '1px solid #000', borderRight: '1px solid #000' }}>{tour.title}</TableCell>
            
              <TableCell style={{ borderBottom: '1px solid #000', borderRight: '1px solid #000' }}>{tour.location}</TableCell>
              <TableCell style={{ borderBottom: '1px solid #000', borderRight: '1px solid #000' }}>{tour.Price}</TableCell>
              <TableCell style={{ borderBottom: '1px solid #000', borderRight: '1px solid #000' }}>{tour.purchase}</TableCell>
              <TableCell style={{ borderBottom: '1px solid #000', borderRight: '1px solid #000' }}>{tour.Rating}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Grid>
</Grid>
<Footer/>
    </>
  );
};

export default Usertours;
